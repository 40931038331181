import ApproveInfoList from '../../approveInfoList/1.0.0/index.vue'
export default {
    name: "component_name",
    data() {
        return {
            formData: {
                pageSize: 9,
                currentPage: 1,
                date: [],
                approveType: '',
                totalPage: 1,
                approveStatus: 2
            },
        };
    },
    components: {
        ApproveInfoList
    },
    methods: {},
    created() { },
    activated() { },
    watch: {},
    computed: {},
}
